const systemRouter = {
  title: '系统配置',
  type: 'view',
  name: Symbol('system'),
  route: '/system',
  filePath: 'view/system/index.vue',
  inNav: true,
  icon: 'iconfont icon-system',
  permission: ['超级管理员独有权限'],
}

export default systemRouter
