<template>
  <div class="paging_container">
    <el-pagination v-model:currentPage="currentPage" v-model:page-size="pageSize" :background="true"
      layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
  import { ref } from 'vue'
  /**
   * pageSize: 每页展示的数据量
   * total: 总的数据量
   * removeFlag: 删除某一条记录时的标记
   * currentPage: 当前所在页码
   */
  export default {
  name: 'page',
  props: {
    pageSize: {
      Type: Number,
      Default: 3
    },
    total: {
      Type: Number,
      Default: 0
    },
    currentPage: {
      Type: Number,
      Default: 1
    }
  },
  emits:[
    'page-event'
  ],
  setup(props,ctx){
    const currPage = ref(1);

    /**
     * 当用户点击具体页码时，触发的事件，会返回给父组件一个页码 page
     * @param val
     * @returns {Promise<void>}
     */
    const handleCurrentChange = async (val) => {
      // console.log(`当前页: ${val}`)
      // 因为服务端是从第0页开始，前端是从第1页显示，所以需要做-1操作
      const page = val
      console.log(`发送给后端的页码：${page}`)
      // 组件内部发出一个点击事件，传给父组件的数据是页码page，然后父组件中调用‘获取当前页数据的方法’
      ctx.emit('page-event', page)
    }
    /**
     * 用户点击‘删除’按钮时，重新构建页面分页数据
     * @param currPage
     */
    function rebuildPage(currPage){
      console.log(`rebuildPage中的当前页：${currPage}`)
      // 向上取整,有小数就整数部分加1，获取总页数
      const totalPage = Math.ceil(this.total / this.pageSize)
      console.log(`总页数：${totalPage}`)
      // 判断当前请求的页数，是不是最后一页，如果不是最后一页，那么还需要请求当前页数据
      if (currPage !== totalPage) {
        handleCurrentChange(currPage)
        return
      }
      // 取最后一页的数据个数，如果为0，则认为没有散页数据
      const num = props.total % props.pageSize
      console.log(`余数：${num}`)
      if (num !== 1) {
        // 如果 mun ！= 1，那么重新获取当前页的数据，就可以了
       handleCurrentChange(currPage)
      } else {
        // 如果 === 1，那么需要获取前一页的数据
       handleCurrentChange(currPage - 1)
      }
    }

    return {
      currPage,
      handleCurrentChange
    }
  }
}
</script>

<style scoped lang="scss">
.paging_container {
  position: absolute;
  margin-top: 20px;
  margin-right: 20px;
  right: 20px;
}
</style>
