const meterRouter = {
  route: null,
  name: null,
  title: '仪表管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/meter/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '仪表列表',
      type: 'view',
      name: 'MeterList',
      route: '/meter/list',
      filePath: 'view/meter/meter-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    }
  ],
}

export default meterRouter
