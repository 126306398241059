const activityRouter = {
  route: null,
  name: null,
  title: '活动管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-coupon-list',
  filePath: 'view/activity/', // 文件路径
  order: null,
  inNav: true,
  permission: ['活动列表'],
  children: [
    {
      title: '活动分类',
      type: 'view',
      name: 'actCateList',
      route: '/activity/category',
      filePath: 'view/activity/act-category.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['活动分类'],
    },
    {
      title: '活动列表',
      type: 'view',
      name: 'activityList',
      route: '/activity/list',
      filePath: 'view/activity/act-list.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['活动列表'],
    },
    {
      title: '券活动信息',
      type: 'view',
      name: 'activityUpdate',
      route: '/activity/update',
      filePath: 'view/activity/act-update.vue',
      inNav: false,
      icon: 'iconfont icon-default',
      permission: ['新增券活动', '编辑券活动', '活动详情'],
    },
  ],
}

export default activityRouter
