const bannerRouter = {
    route: null,
    name: null,
    title: '广告管理',
    type: 'folder', // 类型: folder, tab, view
    icon: 'iconfont icon-banner',
    filePath: 'view/banner/', // 文件路径
    order: null,
    inNav: true,
    permission: ['广告位列表'],
    children: [
        {
            route: '/banner/list',
            name: Symbol('banner'),
            title: '广告管理',
            type: 'view',
            icon: 'iconfont icon-default',
            filePath: 'view/banner/banner-list.vue',
            inNav: true,
            permission: ['广告位列表'],
        },
        // {
        //     title: '添加广告位',
        //     type: 'view',
        //     name: 'adCreate',
        //     route: '/banner/add',
        //     filePath: 'view/banner/banner-add.vue',
        //     inNav: false,
        //     icon: 'iconfont icon-default',
        // },
    ],
}

export default bannerRouter