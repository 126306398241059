const informationRouter = {
  route: '/information/list',
  name: Symbol('information'),
  title: '帮助文档',
  type: 'view', // 类型: folder, tab, view
  icon: 'iconfont icon-information',
  filePath: 'view/information/info-list', // 文件路径
  order: null,
  inNav: true,
  permission: ['文档列表'],
}

export default informationRouter
