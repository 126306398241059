const merchantRouter = {
  route: null,
  name: null,
  title: '商家管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-merchant-list',
  filePath: 'view/merchant/', // 文件路径
  order: null,
  inNav: true,
  permission: ['商家列表', '行业分类列表'],
  children: [
    {
      title: '商家行业',
      type: 'view',
      name: 'merchantCategory',
      route: '/merchant/category',
      filePath: 'view/merchant/merchant-category.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['行业分类列表'],
    },
    {
      title: '商家列表',
      type: 'view',
      name: 'merchantList',
      route: '/merchant/list',
      filePath: 'view/merchant/merchant-list.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['商家列表'],
    },
    {
      title: '商家信息',
      type: 'view',
      name: 'merchantUpdate',
      route: '/merchant/update',
      filePath: 'view/merchant/merchant-update.vue',
      inNav: false,
      permission: ['新增商家', '编辑商家'],
    },
  ],
}

export default merchantRouter
