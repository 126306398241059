const appRouter = {
  title: '平台配置',
  type: 'view',
  name: Symbol('application'),
  route: '/application',
  filePath: 'view/application/app-list.vue',
  inNav: true,
  icon: 'iconfont icon-application',
  permission: ['超级管理员独有权限'],
}

export default appRouter
