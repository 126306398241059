const WhiteListRouter = {
  route: '/white/list',
  name: Symbol('white'),
  title: '白名单管理',
  type: 'view', // 类型: folder, tab, view
  icon: 'iconfont icon-whiteList',
  filePath: 'view/white/list', // 文件路径
  order: null,
  inNav: true,
  permission: ['白名单管理'],
}

export default WhiteListRouter
