const logRouter = {
  title: '日志管理',
  type: 'view',
  name: Symbol('log'),
  route: '/log',
  filePath: 'view/log/log.vue',
  inNav: true,
  icon: 'iconfont icon-rizhiguanli',
  // order: 2,
  permission: ['查询所有日志'],
}

export default logRouter