const activityRouter = {
  route: null,
  name: null,
  title: '优惠券统计',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-statistics',
  filePath: 'view/statistics/', // 文件路径
  order: null,
  inNav: true,
  permission: ['发放列表', '核销列表', '活动统计列表', '商家统计列表'],
  children: [
    {
      title: '发放记录',
      type: 'view',
      name: 'activitySendRecord',
      route: '/statistics/send-record',
      filePath: 'view/statistics/send-record.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['发放列表'],
    },
    {
      title: '核销记录',
      type: 'view',
      name: 'activityUseRecord',
      route: '/statistics/use-record',
      filePath: 'view/statistics/use-record.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['核销列表'],
    },
    {
      title: '活动统计',
      type: 'view',
      name: 'activityStatistics',
      route: '/statistics/activity',
      filePath: 'view/statistics/activity-statistics.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['活动统计列表'],
    },
    {
      title: '商家统计',
      type: 'view',
      name: 'activityMerchantStatistics',
      route: '/statistics/mer-statistics',
      filePath: 'view/statistics/merchant-statistics.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['商家统计列表'],
    },
  ],
}

export default activityRouter
