const meterRouter = {
  route: null,
  name: null,
  title: '会员管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-user',
  filePath: 'view/user/', // 文件路径
  order: null,
  inNav: true,
  permission: ['会员列表'],
  children: [
    {
      title: '会员列表',
      type: 'view',
      name: 'UserList',
      route: '/user/list',
      filePath: 'view/user/user-list.vue',
      inNav: true,
      icon: 'iconfont icon-default',
      permission: ['会员列表'],
    },
  ],
}

export default meterRouter
